<template>
  <section class="about" style="background: #FFF; position: relative;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner tnf-unify-banner"
      >
      <h6>解决方案</h6>
      <h3>建筑洞口一体化解决方案</h3>
      <h4><div class="yellow-line"></div></h4>
      <h5 class=" hidden-sm-and-down">
        <div>特诺发建筑洞口一体化解决方案以TRYBA 特诺发节能门窗系统为核心，结合外遮阳卷帘、纱窗、外窗台板、覆膜门窗套构件，形成完善的建筑洞口集成体系</div>
      </h5>
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap mt-12 mb-12>
        <v-flex xs12 sm12 md7 lg7>
          <h5 class="yellow-title">TRYBA 特诺发</h5>
          <h3 class="un-title">洞口一体化解决方案</h3>
          <h4 style="font-size: 20px; font-weight: 500; color: #0D65A5; line-height: 30px;">建筑洞口一体化解决方案以特诺发节能门窗系统为核心</h4>
          <p style="font-size: 16px; font-weight: 400; color: #0B1531; line-height: 32px;margin-top:20px;">结合外遮阳卷帘、纱窗、外窗台板、覆膜门窗套构件，形成完善的建筑洞口集成体系，增强隔热、保温、隔音降噪功能；防蚊虫粉尘、遮光调光、防窥防盗；保护洞口内外墙体并具有和谐一致的装饰性能，丰富建筑表皮，并轻松满足未来不断提升的建筑节能设计标准。TRYBA 特诺发节能门窗系统选材等，以最大化保温隔热、隔音降噪为原则，将与门窗有关的各个部分，如型材、玻璃、五金与辅件、密封胶条的选材等，结合合理的开启形式设计、组装设备与工艺、现场安装技术等各个方面作为一个整体考虑，经过精心设计形成的具有全局观念的节能门窗系统。</p>
        </v-flex>
        <v-flex xs12 sm12 md5 lg5 pa-md-10 pa-lg-10>
          <v-img
            max-height="372"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_right.jpeg"
            contain
            position="left 0 center"
          >
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm12 md12 lg12>
          <h3 class="un-title">建筑洞口造成能源流失的主要原因</h3>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4>
          <v-layout wrap ma-2 ma-md-4 ma-lg-4 pa-4 class="un-yy">
            <v-flex xs12 sm12 md12 lg12>
              <v-layout wrap>
                <v-flex xs8 sm8 md9 lg9 class="un-yy-title">整窗 U值过高</v-flex>
                <v-flex xs4 sm4 md3 lg3 class="un-yy-num">01</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <p class="un-yy-p">窗框、门框隔热差（如铝制框架缺乏断桥隔热）；玻璃配置落后（单玻或简陋的双玻）</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4>
          <v-layout wrap ma-2 ma-md-4 ma-lg-4 pa-4 class="un-yy">
            <v-flex xs12 sm12 md12 lg12>
              <v-layout>
                <v-flex xs8 sm8 md9 lg9 class="un-yy-title">密封性差</v-flex>
                <v-flex xs4 sm4 md3 lg3 class="un-yy-num">02</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <p class="un-yy-p">框扇间的空隙大，密封性差；错误和未受专业训练的门窗安装</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4>
          <v-layout wrap ma-2 ma-md-4 ma-lg-4 pa-4 class="un-yy">
            <v-flex xs12 sm12 md12 lg12>
              <v-layout wrap>
                <v-flex xs8 sm8 md9 lg9 class="un-yy-title">不具备遮阳调光功能</v-flex>
                <v-flex xs4 sm4 md3 lg3 class="un-yy-num">03</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <p class="un-yy-p">没有配备外遮阳系统；</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm12 md12 lg12>
          <h3 class="un-title">建筑洞口一体化解决方案/完美门窗系统</h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon6.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">节能保温</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">通过有效阻隔室内外的冷热气体交换，达到大幅降低建筑制冷/采暖的能耗。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon5.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">隔音降噪</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">高密度帘片内芯，卷帘完全闭合时，帘片衔接紧凑密闭，有效降低噪音10-12dB。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon4.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">遮阳调光</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">帘片孔隙的穿插设计，可选择光线部分或完全的进入，在闭合时遮光效果达100%。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon3.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">安全防盗</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">独有柔性椎锁设计，卷帘窗完全闭合时能有效防止非正常开启。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon2.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">私密守护</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">卷帘窗半闭合或全闭合时，可有效阻挡外界视线，保护家人隐私。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4>
          <v-img
            height="50"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_icon1.png"
            contain
            position="left 0 center"
          >
            <v-layout wrap class="un-icon">
              <v-flex xs12 sm12 md12 lg12 class="un-icon-title">智能科技</v-flex>
              <v-flex xs12 sm12 md12 lg12 class="un-icon-p">单控、组控、总控等控制系统以及可接入家居智能系统全面应用，尊享智能科技生活。</v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm12 md12 lg12 mb-4>
          <h3 class="un-title">TRYBA®特诺发感心服务</h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-layout wrap>
            <v-flex xs12 sm12 md6 lg6 xl6 pl-xs-4 pl-sm-4 pl-md-12 pl-lg-12 pl-xl-12>
              <div class="un-gx" style="background: url(https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_bottom_bg1.png) left center no-repeat;background-size: 50px 50px;">
                <div>免费提供专业的产品及技术咨询<br/>提供样品、现场勘查及指导等<br/>接受定制、市场报价<br/>合同协议签订</div>
              </div>
              <div class="un-gx" style="background: url(https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_bottom_bg2.png) left center no-repeat;background-size: 50px 50px;">
                <div>定制解决方案，产品设计<br/>下单生产<br/>品质管控<br/>技术、检测、安装一体的标准项目管控<br/>三级项目验收（技术验收、安装验收、客户验收）</div>
              </div>
              <div class="un-gx" style="background: url(https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_bottom_bg3.png) left center no-repeat;background-size: 50px 50px;">
                <div>一个回访（产品验收一个月，进行使用回访）<br/>故障排除：24小时联系客户<br/>主要零部件提供两年免费保养</div>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl6>
              <v-img
                max-height="391"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/un_bottom.jpg"
                contain
                position="left"
              >
              </v-img>
              <h5 style="height:16px;font-size:16px;font-weight:400;color:#666;line-height:24px;margin-top: 10px;">为客户提供物有所值的特色服务和超值产品</h5>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 12
  }),
  created () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '../css/unify.css';
</style>
